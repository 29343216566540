import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import SimpleCard from "../components/simpleCard"
import Categorie from "../components/categorie"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    marginBottom: "100px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  articoli: {
    marginTop: "40px",
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  button: {
    backgroundColor: "#f2545b",
    color: "#fff",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "20px",
    fontWeight: 900,
    margin: "0.3rem",
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
}))

const Articoli = ({ data: { articoli, categories } }) => {
  const classes = useStyles()

  return (
    <Layout title="Blog">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Articoli
                </h1>
                <p style={{ color: "#fff" }}></p>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Container>
        </section>
        <Categorie categories={categories} prefix="articoli" />
        <section className={classes.articoli}>
          <Container>
            <Grid container spacing={3}>
              {articoli.nodes.map((post, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <SimpleCard
                    title={post.titolo}
                    slug={"/articoli/" + post.slug}
                    data={post.data}
                    descrizione={post.descrizione}
                    image={post.foto}
                    type="articoli"
                    key={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Articoli

export const query = graphql`
  query($categoria: String!) {
    articoli: allContentfulArticolo(
      filter: {
        node_locale: { eq: "it-IT" }
        categorie: { elemMatch: { slug: { glob: $categoria } } }
      }
      sort: { order: DESC, fields: data }
    ) {
      nodes {
        titolo
        slug
        descrizione
        data(formatString: "DD/MM/YYYY")
        foto {
          title
          description
          fluid {
            src
          }
        }
      }
    }
    categories: allContentfulCategoria(
      filter: { node_locale: { eq: "it-IT" } }
    ) {
      nodes {
        titolo
        slug
      }
    }
  }
`
